import * as React from 'react'
import Layout from '../../components/Layout'
import Elements from './elements/Elements'
import { deentitize } from '../../helpers/common'
import Subscribe from '../../components/Subscribe'
import HomeBanner from "./includes/HomeBanner"
import { Helmet } from 'react-helmet'

const HomePage = ({ pageContext: { page, nodes } }) => {
    const elementalContent = page.elementalContent ? deentitize(page.elementalContent) : null
    const sliders = page.homepageHeaderSlider.nodes
    return (
        <Layout>
            <Helmet>
              <title>{ page.title } - School Furniture</title>
            </Helmet>
            {sliders.length > 0 && (
            <section className="pt-0 pb-0">
                <HomeBanner items={sliders}/>
            </section>
            )}
            {elementalContent && (
            <Elements data={elementalContent}/>
            )}
            <Subscribe />
        </Layout>
    )
}

export default HomePage
