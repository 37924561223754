import * as React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Title from "../../../components/Layout/Title";

function CustomNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="banner-arrow next" style={{ ...style }} onClick={onClick} />
  );
}

function CustomPrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="banner-arrow prev" style={{ ...style }} onClick={onClick} />
  );
}

const HomeBanner = ({ items }) => {
  const settings = {
    centerMode: false,
    infinite: true,
    dots: false,
    slidesToShow: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings} className="banner-slider">
      {items.map((item, idx) => {
        let ctaLink =
          item.cta.type === "SiteTree" ? item.cta.siteTree.link : item.cta.url;
        let target = item.cta.openInNewWindow === true ? "_blank" : "_self";
        return (
          <div key={`homeslider-${item.id}`} className="banner-item">
            <div className="banner-item-inner">
              <Container>
                <Row className="justify-content-between">
                  <Col xs={12} lg={4}>
                    <Title title={item.title}></Title>
                  </Col>
                  <Col xs={12} lg={5}>
                    <div className="d-flex flex-column align-items-start">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                      {item.cta.title && (
                        <a
                          href={ctaLink}
                          className="btn btn-outline-primary"
                          target={target}
                        >
                          {item.cta.title}
                        </a>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            {item.backgroundImage && (
              <div className="banner-item-image">
                {item.cta.title ? (
                        <a  href={ctaLink} target={target}><img src={item.backgroundImage.absoluteLink} alt="" /></a>
                ):(
                    <img src={item.backgroundImage.absoluteLink} alt="" />
                )}
              </div>
            )}
          </div>
        );
      })}
    </Slider>
  );
};

export default HomeBanner;
